import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faEquals } from '@fortawesome/free-solid-svg-icons/faEquals'
import { faStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faStop as faStopSolid } from '@fortawesome/free-solid-svg-icons/faStop'
import { faPlay as faPlaySolid } from '@fortawesome/free-solid-svg-icons/faPlay'
import { faPause as faPauseSolid } from '@fortawesome/free-solid-svg-icons/faPause'

export default [
  faTimesCircle,
  faCheckCircle,
  faEquals,
  faStar,
  faSquare,
  faEllipsisV,
  faBars,
  faWrench,
  faCircle,
  faStopSolid,
  faPlaySolid,
  faPauseSolid,
]
